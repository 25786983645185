/* eslint-disable no-underscore-dangle */
import { useMutation } from "@apollo/client";
import { Alert } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import { UploadContext } from "@components/reusable_ui/useUpload";

import CreateApplicationAttachments from "@graphql/mutations/CreateApplicationAttachments.graphql";

import useFeatureFlag from "@lib/useFeatureFlag";
import useShrine from "@lib/useShrine";

import { isPartnerAccessible } from "./OtherPartnerDocumentsTable";
import UnmatchedRecordsTable from "./UnmatchedRecordsTable";

/** Other/Misc table for non-CBO/partner documents */
const OtherDocumentsTable = ({
  application,
  unmatchedRecords,
  showingOtherPartnerDocumentsTable,
}) => {
  const [createApplicationAttachments] = useMutation(
    CreateApplicationAttachments
  );
  const [showAlert, setShowAlert] = useState(false);
  const { flag: ffShrineUploads032024, loading } = useFeatureFlag(
    "ff_shrine_uploads_03_2024"
  );
  const { prepareShrineFiles, ShrineErrorAlert } = useShrine();
  const uploadProvider = ffShrineUploads032024 ? "shrine" : "refile";

  // If the other partner documents table is showing, filter out records that are not accessible to the partner agency.
  // Otherwise, show all records, as there may be records that would have been shown by the partner table that would not
  // otherwise be visible.
  const records = showingOtherPartnerDocumentsTable
    ? unmatchedRecords.filter(record => !isPartnerAccessible(record))
    : unmatchedRecords;

  const uploadFiles = async files => {
    if (files.length === 0) {
      setShowAlert(true);

      return;
    }

    let fileVariables;

    if (ffShrineUploads032024) {
      fileVariables = {
        files: [],
        shrineFiles: await prepareShrineFiles(files),
      };
    } else {
      fileVariables = { files };
    }

    await createApplicationAttachments({
      variables: {
        applicationId: application.id,
        category: "otherUploadedByAdmin",
        ...fileVariables,
      },
    });
  };

  // avoid rendering the table until the feature flag is loaded.
  // it can cause a race condition if we try uploading when the feature flag is on,
  // but the ffShrineUploads032024 variable is still undefined while loading.
  if (loading) return null;

  return (
    <UploadContext.Provider value={{ uploadProvider }}>
      <UnmatchedRecordsTable
        title={I18n.t(
          "javascript.components.application_requirements.other_documents_table.title"
        )}
        {...{ application, records, uploadFiles }}
      />

      <ShrineErrorAlert />
      <Alert
        hidden={!showAlert}
        isAlert={true}
        title={I18n.t("javascript.components.common.alert")}
        onSubmit={() => setShowAlert(false)}
        submitText={I18n.t("attachments.errors.ok")}
      >
        {I18n.t("attachments.errors.unpermitted_format")}
      </Alert>
    </UploadContext.Provider>
  );
};

OtherDocumentsTable.propTypes = {
  application: PropTypes.object.isRequired,
  unmatchedRecords: PropTypes.array.isRequired,
  showingOtherPartnerDocumentsTable: PropTypes.bool.isRequired,
};

export default OtherDocumentsTable;
