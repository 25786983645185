import { InputFilterable } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react/jsx-runtime";
import { agencyServicesPath } from "routes";

import { translationWithRoot } from "@components/T";

const { T } = translationWithRoot(
  "prevention.agency_service_referrals.form_page"
);

const convertToServiceOptions = services =>
  services?.map(service => ({
    label: `${service.name} - ${service.providerAgency.name}`,
    value: service.id,
  })) || [];

/**
 * A component to manage the selection of a service and the rates associated to it.
 */
const ServiceAndRateInput = ({
  services,
  onServiceChange,
  onRateChange,
  selectedService,
  selectedRates,
}) => {
  const ratesOptions = services
    .find(service => service.id === selectedService.value)
    ?.agencyServiceRates?.map(rate => ({
      value: rate.id,
      label: rate.label,
    }));

  return (
    <Fragment>
      <InputFilterable
        values={convertToServiceOptions(services)}
        label={
          <span>
            <T t={"services"} />
            &nbsp;
            <a href={agencyServicesPath()} target={"_blank"} rel={"noreferrer"}>
              <T t={"view_services"} />
            </a>
          </span>
        }
        onChange={onServiceChange}
        required
        value={selectedService}
      />
      <InputFilterable
        disabled={!selectedService?.value}
        values={ratesOptions}
        label={<T t={"service_rates"} />}
        onChange={onRateChange}
        value={selectedRates}
        required
        isMulti
      />
    </Fragment>
  );
};

ServiceAndRateInput.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  onServiceChange: PropTypes.func.isRequired,
  onRateChange: PropTypes.func.isRequired,
  selectedService: PropTypes.object,
  selectedRates: PropTypes.arrayOf(PropTypes.object),
};

export default ServiceAndRateInput;
