import { Text } from "@heart/components";
import I18n from "i18n-js";
import { isEmpty, isNumber } from "lodash";
import PropTypes from "prop-types";

import styles from "./Input.module.scss";

/** A conversion function that handles values that may be an array of label/value
 * pairs or a single label/value pair
 */
export const valueForLabelValueArrayOrObject = (value = []) =>
  Array.isArray(value)
    ? value.map(({ label }) => label).join(", ")
    : value.label;

/** An accessible representation of a a disabled input
 *
 * Rather than using a disabled input, we want to represent the input in an a11y
 * friendly way. There are a few reasons why disabled inputs aren't a11y:
 * - for folks using assistive tech, generally it'll skip right on over any disabled
 * UI elements. this means for those users it'll be like those elements don't exist
 * - for folks with visual impairment, it's not an accessibility requirement that
 * disabled UI elements meet color contrast requirements. While of course we can go
 * above and beyond that requirement, currently our disabled elements don't meet color
 * contrast requirements
 *
 * This component should **ONLY** be used within Heart components
 */
const InputReadOnly = ({ label: inputLabel, value }) => (
  <Text className={styles.disabled}>
    {inputLabel}:{" "}
    <Choose>
      <When condition={isNumber(value) || !isEmpty(value)}>{value}</When>
      <Otherwise>
        <i>
          {I18n.t("javascript.components.heart.components.inputs.no_value")}
        </i>
      </Otherwise>
    </Choose>
  </Text>
);
InputReadOnly.propTypes = {
  /** Label for this input */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Value for this input. When not provided "No value" will be displayed
   * in italics
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InputReadOnly;
