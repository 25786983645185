import { useQuery } from "@apollo/client";

import AgencyHumanPermissionsQuery from "@graphql/queries/AgencyHumanPermissions.graphql";

import { policy } from "@lib/graphqlHelpers";

/** A reusable hook to grab the permissions for a user in relation to
 * the provided agency human.
 */
const useAgencyHumanPermissions = agencyHumanId => {
  const { data: agencyHumanPermissionsData = {} } = useQuery(
    AgencyHumanPermissionsQuery,
    {
      variables: { ids: [agencyHumanId] },
    }
  );

  const { agencyHumanDetails = [{ permissions: [] }] } =
    agencyHumanPermissionsData;
  // We're only passing in one id so we can take the first (and only) response object
  const { permissions } = agencyHumanDetails[0];
  const perms = policy({ permissions });

  return perms;
};

export default useAgencyHumanPermissions;
