import { Flex, Icons, IconMenu, Link, Pill } from "@heart/components";
import listStyles from "@heart/core/invisible_list.module.scss";
import { intersection, isEmpty, upperCase } from "lodash";
import {
  newFamilyFindingContactLogPath,
  editRelationshipAsChildConnectionPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import {
  mapConstantSetToValues,
  mapNestedEnumConstantToValues,
} from "@lib/constantsConversion";
import { formatDateTimeAsShortDate } from "@lib/dates";
import { policy } from "@lib/graphqlHelpers";

import {
  EMOTIONAL_RELATIONSHIP_STATUSES,
  LEVELS_OF_SUPPORT,
  PLACEMENT_PROVIDER_ELIGIBILITIES,
  RECOMMENDED_PLACEMENT_PROVIDER_ELIGIBILITIES_SUB_OPTIONS,
  OTHER,
} from "@root/constants";

import contactStatusMap from "../contact_logs/contactStatusMap";
import {
  determineNonKeystoneId,
  determineSelectedPlacementProviderEligibilities,
} from "./relationshipConversion";
import {
  determineName,
  determineRelationshipTypeForTable,
  filterLevelsOfSupport,
  sortByContactStatus,
  sortByEmotionalRelationship,
  sortByLevelsOfSupport,
  sortByName,
  sortByPlacementProviderEligibility,
  sortByRelationshipType,
} from "./sortRelationships";

const { t: relationshipT } = translationWithRoot("relationships.table");
const { t: enumT } = translationWithRoot("activerecord.enums.relationships", {
  escapeJavascriptRoot: true,
});
const { t: contactLogEnumT } = translationWithRoot(
  "activerecord.enums.family_finding_contact_log",
  {
    escapeJavascriptRoot: true,
  }
);

const placementProviderEligibilityMap = {
  current_placement: "success",
  recommended: "success",
  for_placement: "success",
  for_placement_consideration: "success",
  willing: "info",
  unable: "alert",
  prohibited: "warning",
  unwilling: "warning",
  not_recommended: "warning",
  not_applicable: "neutral",
};
const emotionalRelationshipMap = {
  close: "success",
  cordial: "info",
  distant: "alert",
  no_prior_interaction: "alert",
  contentious: "warning",
};

const nameCol = ({ keystoneAgencyHumanId }) => ({
  columnName: { name: relationshipT("name") },
  id: "contactName",
  cell: ({ destinationAgencyHuman, sourceAgencyHuman }) => {
    const { href, name } = determineName({
      keystoneAgencyHumanId,
      destinationAgencyHuman,
      sourceAgencyHuman,
    });
    return <Link href={href}>{name}</Link>;
  },
  columnSort: sortByName({ keystoneAgencyHumanId }),
  filter: {
    label: relationshipT("search_relatives_and_kin"),
    filterBy: ({ data: relationship, searchTerm }) =>
      upperCase(
        determineName({
          ...relationship,
          keystoneAgencyHumanId,
        }).name
      ).includes(upperCase(searchTerm)),
    type: "search",
  },
});

const relationshipTypeCol = () => ({
  columnName: { name: relationshipT("relationship_type") },
  id: "relationshipType",
  cell: relationship => determineRelationshipTypeForTable(relationship),
  columnSort: sortByRelationshipType,
  filter: {
    label: relationshipT("search_relationship_types"),
    filterBy: ({ data: relationship, searchTerm }) =>
      upperCase(determineRelationshipTypeForTable(relationship)).includes(
        upperCase(searchTerm)
      ),
    type: "search",
  },
});

const contactStatusCol = () => ({
  columnName: {
    name: relationshipT("contact_status"),
    justify: "center",
  },
  id: "contactStatus",
  columnSort: sortByContactStatus,
  cell: ({ latestContactLog }) => (
    <Flex align="center" column>
      <Pill
        variant={contactStatusMap[latestContactLog?.status] || "warning"}
        text={
          latestContactLog
            ? contactLogEnumT(`status.${latestContactLog.status}`)
            : relationshipT("not_contacted")
        }
      />
      <If condition={latestContactLog}>
        {formatDateTimeAsShortDate(latestContactLog.contactedOn)}
      </If>
    </Flex>
  ),
});

const levelOfSupportCol = () => ({
  columnName: { name: relationshipT("level_of_support") },
  id: "levelsOfSupport",
  columnSort: sortByLevelsOfSupport,
  cell: ({ levelsOfSupport }) => (
    <ul className={listStyles.invisibleList}>
      {filterLevelsOfSupport(levelsOfSupport).map(los => {
        if (los === OTHER) return levelsOfSupport[OTHER].details;
        return <li key={los}>{enumT(`level_of_support.${los}`)}</li>;
      })}
    </ul>
  ),
  filter: {
    label: relationshipT("search_levels_of_support"),
    values: mapNestedEnumConstantToValues({
      constant: LEVELS_OF_SUPPORT,
    }),
    filterBy: ({ data: { levelsOfSupport }, searchTerm }) =>
      !isEmpty(intersection(Object.keys(levelsOfSupport), searchTerm)),
    isMulti: true,
    type: "select",
  },
});

const placementOptionCol = ({ ffPlacementOptions }) => {
  const eligibilitiesWithRecommendedOptions = [
    ...PLACEMENT_PROVIDER_ELIGIBILITIES,
  ];

  if (ffPlacementOptions) {
    eligibilitiesWithRecommendedOptions.splice(
      2,
      0,
      ...RECOMMENDED_PLACEMENT_PROVIDER_ELIGIBILITIES_SUB_OPTIONS
    );
  }

  return {
    columnName: {
      name: relationshipT("placement_option"),
      justify: "center",
    },
    id: "placementOption",
    columnSort: sortByPlacementProviderEligibility,
    cell: ({ placementProviderEligibilitiesJson }) => (
      <Flex
        column
        align="center"
        gap="300"
        as="ul"
        className={listStyles.invisibleList}
      >
        {determineSelectedPlacementProviderEligibilities(
          placementProviderEligibilitiesJson
        ).map(eligibility => (
          <li key={eligibility}>
            <Pill
              variant={placementProviderEligibilityMap[eligibility]}
              text={enumT(`placement_provider_eligibilities.${eligibility}`)}
            />
          </li>
        ))}
      </Flex>
    ),
    filter: {
      label: relationshipT("search_placement_options"),
      values: mapConstantSetToValues({
        constant: eligibilitiesWithRecommendedOptions,
        translationKey: "relationships.placement_provider_eligibilities",
      }),
      filterBy: ({
        data: { placementProviderEligibilitiesJson },
        searchTerm,
      }) =>
        !isEmpty(
          intersection(
            determineSelectedPlacementProviderEligibilities(
              placementProviderEligibilitiesJson
            ),
            searchTerm
          )
        ),
      isMulti: true,
      type: "select",
    },
  };
};

const emotionalRelationshipCol = () => ({
  columnName: {
    name: relationshipT("emotional_relationship"),
    justify: "center",
  },
  id: "emotionalRelationship",
  columnSort: sortByEmotionalRelationship,
  cell: ({ emotionalRelationshipStatuses }) => (
    <Flex justify="center" align="center" column>
      {emotionalRelationshipStatuses.map(status => (
        <Pill
          key={status}
          variant={emotionalRelationshipMap[status]}
          text={enumT(`emotional_status.${status}`)}
        />
      ))}
    </Flex>
  ),
  filter: {
    label: relationshipT("search_emotional_relationships"),
    values: mapConstantSetToValues({
      constant: EMOTIONAL_RELATIONSHIP_STATUSES,
      translationKey: "relationships.emotional_status",
    }),
    filterBy: ({ data: { emotionalRelationshipStatuses }, searchTerm }) =>
      !isEmpty(intersection(emotionalRelationshipStatuses, searchTerm)),
    isMulti: true,
    type: "select",
  },
});

const actionsCol = ({
  keystoneAgencyHumanId,
  setRelationshipIdToDelete,
  setRelationshipToView,
  canCreateSearch,
  setIdForSearchConfirmationModal,
}) => ({
  columnName: { name: relationshipT("actions"), justify: "end" },
  id: "actions",
  cell: relationship => {
    const nonKeystoneAgencyHumanId = determineNonKeystoneId({
      keystoneAgencyHumanId,
      relationship,
    });

    const linkItems = [
      {
        description: relationshipT("view_relationship"),
        onClick: () => setRelationshipToView(relationship),
      },
    ];
    if (policy(relationship).mayEdit()) {
      linkItems.push({
        description: relationshipT("edit_relationship"),
        href: editRelationshipAsChildConnectionPath({
          /** In the context of the relationships table the child is the keystone agency
           * human, but in the context of editing the relationship we want the non-child
           * to be the keystone and all members of the sibling group to be the non-keystone
           * agency humans, so in crafting this link we're flipping the ids
           */
          child_agency_human_id: keystoneAgencyHumanId,
          keystone_agency_human_id: nonKeystoneAgencyHumanId,
        }),
      });
    }
    if (policy(relationship).mayCreateContactLog()) {
      linkItems.push({
        description: relationshipT("add_contact_log"),
        href:
          `${newFamilyFindingContactLogPath()}?child_agency_human_id=${keystoneAgencyHumanId}` +
          `&contact_agency_human_ids=${nonKeystoneAgencyHumanId}`,
      });
    }
    if (policy(relationship).mayDestroy()) {
      linkItems.push({
        description: relationshipT("delete_relationship"),
        onClick: () => setRelationshipIdToDelete(relationship.id),
      });
    }
    if (canCreateSearch) {
      linkItems.push({
        description: relationshipT("launch_kin_search"),
        onClick: () =>
          setIdForSearchConfirmationModal(nonKeystoneAgencyHumanId),
      });
    }

    return (
      <IconMenu
        icon={Icons.EllipsisVertical}
        menuDescription={relationshipT("view_additional")}
        linkItems={linkItems}
        rightAligned
      />
    );
  },
});

/** Determine which columns should be displayed in the Relationships table */
const getColumns = ({ ffPlacementOptions, tab, ...props }) => {
  if (tab === "potentialConnections") {
    return [
      nameCol(props),
      relationshipTypeCol(),
      contactStatusCol(),
      actionsCol(props),
    ];
  }

  return [
    nameCol(props),
    relationshipTypeCol(),
    contactStatusCol(),
    levelOfSupportCol(),
    placementOptionCol({ ffPlacementOptions }),
    emotionalRelationshipCol(),
    actionsCol(props),
  ];
};

export default getColumns;
