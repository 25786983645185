import { Flex, GraphQLDataTable, Icons, Link } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useMemo, useState } from "react";
import { preventionAgencyServiceReferralPath } from "routes";
import { If } from "tsx-control-statements/components";

import { translationWithRoot } from "@components/T";
import PreventionDocumentDeleteModal from "@components/prevention/profile/PreventionDocumentDeleteModal";

import AgencyHumanRequirementReconciliation from "@graphql/queries/prevention/AgencyHumanRequirementReconciliation.graphql";
import AgencyServiceReferralRequirementReconciliation from "@graphql/queries/prevention/AgencyServiceReferralRequirementReconciliation.graphql";

import UploadChildDocsButton from "./UploadChildDocsButton";
import UploadRefDocsButton from "./UploadRefDocsButton";

const { t } = translationWithRoot("prevention.documents_and_requirements");

const associatedRecordColumnConfig = {
  AgencyHuman: {
    columnName: { name: t("associated_service_referral") },
    id: "associatedServiceReferral",
    cell: requirement => {
      const agencyServiceReferral = requirement?.agencyServiceReferral;
      return (
        <Flex row justify="start">
          {agencyServiceReferral ? (
            <Link
              href={preventionAgencyServiceReferralPath(
                agencyServiceReferral.id
              )}
            >
              {agencyServiceReferral.agencyService?.name}
            </Link>
          ) : (
            <span>{t("not_applicable")}</span>
          )}
        </Flex>
      );
    },
  },
  AgencyServiceReferral: {
    columnName: { name: t("associated_child") },
    id: "associatedChild",
    cell: requirement => {
      const childName = requirement?.child?.fullName;
      return (
        <Flex row justify="start">
          {childName ? <span>{childName}</span> : <span>{"--"}</span>}
        </Flex>
      );
    },
  },
};

/**
 * Renders a table of requirements including ad-hoc docs for an agency service referral
 * or a prevention child associated with an agency human
 *
 * @param requirementHolderId - the ID of the requirement holder
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentsAndRequirementsTab = ({
  hideTitle = false,
  requirementHolderId,
  requirementHolderType,
  recipients = [],
  showDocumentUploadButton = false,
}) => {
  const queryMapping = {
    AgencyHuman: {
      query: AgencyHumanRequirementReconciliation,
      queryTitle: "agencyHumanRequirementReconciliation",
    },
    AgencyServiceReferral: {
      query: AgencyServiceReferralRequirementReconciliation,
      queryTitle: "agencyServiceReferralRequirementReconciliation",
    },
  };
  const { columnName, id, cell } =
    associatedRecordColumnConfig[requirementHolderType];
  const { query, queryTitle } = queryMapping[requirementHolderType];

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [requirementIdToDelete, setRequirementIdToDelete] = useState(null);

  const handleDeleteClick = requirementId => {
    setRequirementIdToDelete(requirementId);
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setRequirementIdToDelete(null);
  };

  const columns = useMemo(
    () => [
      {
        columnName: { name: t("document") },
        id: "document",
        cell: data => {
          const uploadedRecordFile = data.records[0].uploadedRecordFiles[0];
          const { filename, downloadUrl, fileId } = uploadedRecordFile;
          return (
            <Flex row gap="100" align="center">
              <Link href={downloadUrl} target="_blank">
                {filename}
              </Link>
              <Icons.FileDownload
                description={t("download", { filename })}
                href={downloadUrl}
                target={`uploadedRecordFile${fileId}`}
              />
            </Flex>
          );
        },
      },
      {
        columnName: { name: t("date_added") },
        id: "dateAdded",
        cell: data => (
          <span>
            {new Date(data.requirement.createdAt).toLocaleDateString("en-US")}
          </span>
        ),
      },
      {
        columnName,
        id,
        cell: data => cell(data.requirement),
      },
      {
        columnName: { name: t("delete") },
        id: "delete",
        cell: data => (
          <Icons.Trash
            description={t("delete_document")}
            onClick={() => handleDeleteClick(data.requirement.id)}
          />
        ),
      },
    ],
    [cell, columnName, id]
  );

  const { UploadButton, UploadBtnModals } =
    requirementHolderType === "AgencyHuman"
      ? UploadChildDocsButton(requirementHolderId)
      : UploadRefDocsButton({
          agencyServiceReferralId: requirementHolderId,
          recipients,
        });

  return (
    <Fragment>
      <If condition={showDocumentUploadButton}>
        <UploadBtnModals />
      </If>
      <GraphQLDataTable
        title={hideTitle ? null : t("section_title")}
        columns={columns}
        queryTitle={queryTitle}
        query={query}
        defaultFilters={{ requirementHolderId }}
        filtersToPreserveOnClear={["requirementHolderId"]}
        emptyStatePrompt={t("default_no_data_available")}
        filters={[]}
        actions={
          <Flex column justify="end">
            <If condition={showDocumentUploadButton}>
              <UploadButton />
            </If>
          </Flex>
        }
      />
      <PreventionDocumentDeleteModal
        handleCloseModal={handleCloseModal}
        isHidden={!showDeleteModal}
        refetchQuery={query}
        requirementHolderId={requirementHolderId}
        requirementIdToDelete={Number(requirementIdToDelete)}
      />
    </Fragment>
  );
};

DocumentsAndRequirementsTab.propTypes = {
  hideTitle: PropTypes.bool,
  requirementHolderId: PropTypes.number.isRequired,
  requirementHolderType: PropTypes.oneOf([
    "AgencyHuman",
    "AgencyServiceReferral",
  ]).isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    })
  ),
  showDocumentUploadButton: PropTypes.bool,
};

export default DocumentsAndRequirementsTab;
