import { useQuery, useMutation } from "@apollo/client";
import {
  ContentTabs,
  PageContainer,
  Surface,
  ArrayDataTable,
  UploadButton,
} from "@heart/components";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { translationWithRoot } from "@components/T";
import UploadTypeDocuments from "@components/application_requirements_shared/UploadTypeDocuments";
import useUploadRow from "@components/reusable_ui/useUploadRow";

import CreateMemberUploadedRecord from "@graphql/mutations/CreateMemberUploadedRecord.graphql";
import StaffRequirementReconciliation from "@graphql/queries/StaffRequirementReconciliation.graphql";

import ProgressCell from "../child_requirements/ProgressCell";
import {
  sortByDocument,
  sortByProgress,
} from "../child_requirements/sortRequirements";

const { t } = translationWithRoot("staff_requirements");

const StaffRequirementTableWrapper = ({
  licenseId,
  collatedAttachmentsPdfPath,
}) => {
  const { data, loading } = useQuery(StaffRequirementReconciliation, {
    variables: { licenseId },
  });

  const [createMemberUploadedRecord] = useMutation(CreateMemberUploadedRecord);

  const uploadFile = ({ fulfillment }) => {
    const { requirement } = fulfillment;
    const {
      humanPlacementProviderRole: { id: memberId },
      uploadType: { slug: uploadTypeSlug },
    } = requirement;

    return async files => {
      await createMemberUploadedRecord({
        variables: {
          memberId,
          uploadTypeSlug,
          files,
        },
      });
    };
  };

  const { aroundLoader } = useUploadRow(uploadFile);

  const tableData = {
    all: [],
    current: [],
    former: [],
  };
  const graphQLData = useMemo(
    () =>
      get(data, "staffRequirementReconciliation.requirementFulfillments") || [],
    [data]
  );

  graphQLData.forEach(record => {
    tableData.all.push(record);
    if (record.requirement.humanPlacementProviderRole.leftAt == null) {
      tableData.current.push(record);
    } else {
      tableData.former.push(record);
    }
  });

  return (
    <PageContainer fullWidth>
      <StaffRequirementsTable
        loading={loading}
        /** We could pull this directly off the graphql data, but we then
         * lose the ability to assert that holderToken is required in all
         * our subsequent components as it will be undefined until data
         * loads.
         */
        holderToken={`License-${licenseId}`}
        tableData={tableData}
        collatedAttachmentsPdfPath={collatedAttachmentsPdfPath}
        /** GraphQL Mutations */
        aroundLoader={aroundLoader}
        uploadFile={uploadFile}
      />
    </PageContainer>
  );
};
StaffRequirementTableWrapper.propTypes = {
  licenseId: PropTypes.number.isRequired,
  collatedAttachmentsPdfPath: PropTypes.string.isRequired,
};

export const StaffRequirementsTable = ({
  loading,
  holderToken,
  tableData,
  /** GraphQL Mutations */
  uploadFile,
  aroundLoader,
}) => {
  const Table = ({ tableDataKey }) => (
    <ArrayDataTable
      loading={loading}
      disableUploadForRow={fulfillment => fulfillment.isOverridden}
      columns={useMemo(
        () => [
          {
            columnName: { name: t("cols.staff_member_name") },
            id: "staff_member_name",
            iconOnlyUpload: true,
            cell: "requirement.humanPlacementProviderRole.fullName",
            columnSort: "requirement.humanPlacementProviderRole.fullName",
            filter: {
              type: "search",
              filterBy: "requirement.humanPlacementProviderRole.fullName",
              label: t("filters.search_staff_member_names"),
            },
          },
          {
            columnName: { name: t("cols.role") },
            id: "role",
            iconOnlyUpload: true,
            cell: "requirement.humanPlacementProviderRole.subroleReadable",
            columnSort:
              "requirement.humanPlacementProviderRole.subroleReadable",
            filter: {
              type: "search",
              filterBy:
                "requirement.humanPlacementProviderRole.subroleReadable",
              label: t("filters.search_roles"),
            },
          },
          {
            columnName: {
              name: t("cols.title"),
            },
            id: "title",
            iconOnlyUpload: true,
            cell: "requirement.title",
            filter: {
              type: "search",
              filterBy: "requirement.title",
              label: t("filters.search_requirement_titles"),
            },
            columnSort: "requirement.title",
          },
          {
            columnName: { name: t("cols.status") },
            id: "status",
            iconOnlyUpload: true,
            cell: fulfillment => <ProgressCell fulfillment={fulfillment} />,
            columnSort: sortByProgress,
          },
          {
            columnName: { name: t("cols.document") },
            id: "document",
            iconOnlyUpload: true,
            cell: fulfillment => (
              <UploadTypeDocuments
                fulfillment={fulfillment}
                holderToken={holderToken}
                /** GraphQL Mutations */
                aroundLoader={aroundLoader}
              />
            ),
            filter: {
              type: "custom_dates",
              filterBy: ({ rowData: { records }, startDate, endDate }) => {
                if (!(startDate || endDate)) return true;

                return !isEmpty(
                  records.filter(
                    record =>
                      record.expirationDate &&
                      (!endDate || record.expirationDate < endDate) &&
                      (!startDate || record.expirationDate > startDate)
                  )
                );
              },
              label: t("filters.expiration_date"),
            },
            columnSort: sortByDocument,
          },
          {
            columnName: {
              name: t("cols.actions"),
              justify: "end",
            },
            id: "actions",
            cell: fulfillment => (
              <UploadButton
                onUpload={uploadFile({
                  fulfillment,
                })}
              />
            ),
          },
        ],
        []
      )}
      data={tableData[tableDataKey]}
    />
  );
  Table.propTypes = { tableDataKey: PropTypes.string.isRequired };

  return (
    <Surface
      hideTitle
      data-testid="staff-requirements-table"
      title="Requirements"
    >
      <ContentTabs
        tabs={[
          {
            title: t("current_staff"),
            contents: Table({ tableDataKey: "current" }),
            count: tableData.current.length,
          },
          {
            title: t("former_staff"),
            contents: Table({ tableDataKey: "former" }),
            count: tableData.former.length,
          },
          {
            title: t("all_staff"),
            contents: Table({ tableDataKey: "all" }),
            count: tableData.all.length,
          },
        ]}
      />
    </Surface>
  );
};
StaffRequirementsTable.propTypes = {
  loading: PropTypes.bool,
  holderToken: PropTypes.string.isRequired,
  tableData: PropTypes.shape({
    all: PropTypes.array.isRequired,
    current: PropTypes.array.isRequired,
    former: PropTypes.array.isRequired,
  }),
  collatedAttachmentsPdfPath: PropTypes.string.isRequired,
  /** GraphQL Mutations */
  uploadFile: PropTypes.func.isRequired,
  aroundLoader: PropTypes.func.isRequired,
};

export default StaffRequirementTableWrapper;
