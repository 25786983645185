import { Flex, LiveRegion } from "@heart/components";
import { useKeyboardEvent, useMountEffect } from "@react-hookz/web";
import { isEmpty, keyBy } from "lodash";
import PropTypes from "prop-types";
import React, {
  createRef,
  Fragment,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";

import { stringifyAndEncode } from "@lib/base64Helpers";
import { setBase64SearchParams } from "@lib/base64SearchParams";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";
import useStateList from "@lib/react-use/useStateList";
import {
  clearAllOtherSearchParams,
  getSearchParamForAttribute,
} from "@lib/searchParams";

import { B64PARAMS } from "@root/constants";

import styles from "./ContentTabs.module.scss";
import TabPanelButton from "./TabPanelButton";
import TabPanelContents from "./TabPanelContents";

/** Useful for testing, generates an encoded version of the tab
 * param used in our base64 encoded params
 */
export const generateTabParam = tabTitle =>
  `${B64PARAMS}=${stringifyAndEncode({ tab: tabTitle })}`;

/**
 * Used to display tabbed contents, and optionally indicate a count
 * for each tab. This count will generally correlate to the number of
 * items displayed in a table on a given tab
 *
 * The tabs also utilize the `tab` query parameter to enable navigation
 * directly to a particular tab, and enable page refreshes without losing
 * the tab that was previously active
 *
 * **Note:** Only **one** instance of `ContentTabs` should appear on a given
 * page as the keyboard navigation will not function independently for
 * each instance. The keyboard nav in Storybook is also a bit as a result of
 * this limitation. Use the "Canvas" view rather than the docs view when
 * testing keyboard navigation
 *
 * The a11y spec for this component can be found [here](https://www.w3.org/WAI/ARIA/apg/example-index/tabs/tabs-manual.html)
 *
 * ## routes and rspec tests
 * there's a helper for generating the base64 param that powers the tab navigation
 * under `NavigationHelper.generate_tab_param`. This can be used for tests that
 * assert on a url that includes a ContentTab parameter, and for route definitions
 * that include a ContentTab parameter.
 *
 * ## javascript and cypress tests
 * similarly, there's a helper for generating the base64 param that powers the tab
 * navigation exported from ContentTabs.js.
 */
const ContentTabs = ({
  showTitlesInContents = false,
  tabs,
  onActiveTabChange,
  "data-testid": testId,
  loading,
}) => {
  const tabSearchParam = useBase64SearchParam("tab");
  const setTabSearchParam = useCallback(
    ({ tabTitle, clearOtherParams, preserveB64Attributes = [] }) => {
      if (clearOtherParams) {
        clearAllOtherSearchParams({ preserveB64Attributes });
      }
      setBase64SearchParams([{ attribute: "tab", value: tabTitle }]);
    },
    []
  );

  /**
   * tabsById gives us a consistent `id` to use when referencing a particular
   * tab. This is helpful for things like a11y aria tags, and the keys which help
   * React keep track of components generated out of a `map`.
   */
  const tabsByTitle = useMemo(
    () =>
      keyBy(
        tabs.map((tab, index) => ({
          id: `navigation-tab-${index}`,
          ref: createRef(null),
          tabPanelTitleRef: createRef(null),
          ...tab,
        })),
        "title"
      ),
    [tabs]
  );

  const [activeTab, setActiveTab] = useState(tabs[0].title);

  useEffect(() => {
    const activeTabFromUrl = () => {
      const legacyTabQueryParam = getSearchParamForAttribute({
        attribute: "tab",
      });
      const selectedParam = tabSearchParam || legacyTabQueryParam;
      if (Object.keys(tabsByTitle).includes(selectedParam)) {
        setTabSearchParam({ tabTitle: selectedParam });
        return selectedParam;
      }
      let clearOtherParams;
      /** If the tab name is not found in our known list of tabs, set it
       * to the first tab of the list and clear all other query params. If
       * it's just not present, preserve any other query params
       */
      if (!isEmpty(selectedParam)) clearOtherParams = true;

      setTabSearchParam({ tabTitle: tabs[0].title, clearOtherParams });
      return tabs[0].title;
    };
    setActiveTab(activeTabFromUrl());
  }, [tabs, tabSearchParam, setTabSearchParam, tabsByTitle]);

  const tabReferences = Object.values(tabsByTitle).map(
    ({ ref: { current } }) => current
  );
  const tabsFocused = tabReferences.includes(document.activeElement);
  /** state used to track which tab is focused */
  const {
    state: focusedTabTitle,
    prev,
    next,
    setState,
    setStateAt,
  } = useStateList(Object.keys(tabsByTitle));

  useKeyboardEvent("ArrowLeft", prev);
  useKeyboardEvent("ArrowRight", next);
  useKeyboardEvent("Home", () => setStateAt(0));
  useKeyboardEvent("End", () => setStateAt(tabs.length - 1));

  /** Initialize our focus state to the active tab in query params or the first tab */
  useMountEffect(() => {
    setState(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (tabsFocused) {
      /* Rotate focus to next tab when focused on a tab */
      const refToFocus = tabsByTitle[focusedTabTitle].ref.current;
      if (tabsFocused && !isEmpty(refToFocus)) refToFocus.focus();
    } else {
      /* Otherwise reset place in state list to active tab */
      setStateAt(Object.keys(tabsByTitle).indexOf(activeTab));
    }
  }, [tabsFocused, focusedTabTitle, tabsByTitle, setStateAt, activeTab]);

  const changeActiveTab = tabTitle => {
    if (onActiveTabChange) {
      onActiveTabChange(tabTitle);
    }
    setActiveTab(tabTitle);
    setTabSearchParam({
      tabTitle,
      clearOtherParams: true,
      preserveB64Attributes: onActiveTabChange ? ["query"] : [],
    });
  };

  return (
    <Fragment>
      <nav
        aria-label="page-content-tabs"
        data-testid={testId}
        aria-busy={loading}
      >
        <Flex
          as="ul"
          gap="0"
          role="tablist"
          className={styles.tabs}
          align="end"
        >
          {Object.values(tabsByTitle).map(({ title, count, ref, id }) => {
            const isActiveTab = activeTab === title;
            return (
              <li role="presentation" key={id}>
                <TabPanelButton
                  id={id}
                  active={isActiveTab}
                  count={count}
                  loading={loading}
                  onClick={() => (isActiveTab ? null : changeActiveTab(title))}
                  tabRef={ref}
                  title={title}
                />
              </li>
            );
          })}
        </Flex>
      </nav>
      <LiveRegion>
        {Object.values(tabsByTitle).map(
          ({ title, contents, tabPanelTitleRef, id }) => {
            const isActiveTab = activeTab === title;
            return (
              <TabPanelContents
                id={id}
                key={id}
                active={isActiveTab}
                contents={contents}
                showTitlesInContents={showTitlesInContents}
                title={title}
                tabPanelTitleRef={tabPanelTitleRef}
              />
            );
          }
        )}
      </LiveRegion>
    </Fragment>
  );
};

ContentTabs.propTypes = {
  /** Adds a loading spinner to the ContentTab buttons if content is loading */
  loading: PropTypes.bool,
  /** Whether to show the tab title at the top of the contents of the tab */
  showTitlesInContents: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      contents: PropTypes.node.isRequired,
      count: PropTypes.number,
    })
  ).isRequired,
  /** Function that is called whenever the active tab is changed. When provided, we will
   * not clear out the `query` search param, allowing folks to preserve filters that have
   * been set if they'd like to do so. See Family Finding's Searches.js for an example of
   * how this is used
   */
  onActiveTabChange: PropTypes.func,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default ContentTabs;
