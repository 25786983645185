import { useMutation } from "@apollo/client";
import { Actions, generateTabParam, Text, toast } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";
import {
  contentFamilyFindingBulkOutreachCampaignPath,
  editFamilyFindingBulkOutreachCampaignPath,
  printFamilyFindingBulkOutreachCampaignPath,
  childRelationshipsDashboardPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import FinalizeBulkOutreachCampaign from "@graphql/mutations/FinalizeBulkOutreachCampaign.graphql";

import { getEncodedBase64SearchParams } from "@lib/base64SearchParams";

import {
  B64PARAMS,
  FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_PRINT,
} from "@root/constants";

import styles from "./BulkOutreachCampaignPreview.module.scss";

const { t } = translationWithRoot(
  "family_finding.bulk_outreach_campaigns.preview"
);

const { T } = translationWithRoot("family_finding.bulk_outreach_campaigns");

const { t: toastT } = translationWithRoot("toast");

/**
 * Component for previewing a bulk outreach campaign.
 *
 * Provides options to go back to editing the content of the campaign
 * or to proceed and generate the letters (or send the email messages) for the campaign.
 */
const BulkOutreachCampaignPreview = ({ id, childId, channel }) => {
  const [finalizeBulkOutreachCampaign, { loading }] = useMutation(
    FinalizeBulkOutreachCampaign,
    {
      // Setting bintiSkipMutationErrorHandling opts out this operation from the
      // global mutation error handler in BintiApolloProvider.
      context: { bintiSkipMutationErrorHandling: true },
    }
  );

  return id ? (
    <Fragment>
      <Text textColor="emphasis-500" textStyle="emphasis-100">
        {t(`${channel}.preview`)}
      </Text>
      <div className={styles.preview}>
        <iframe
          title="bulkOutreachCampaignNotificationPreview"
          src={contentFamilyFindingBulkOutreachCampaignPath(id)}
          className={styles.iframe}
        />
      </div>

      <Text as="p" textStyle="emphasis-100">
        <T t="privacy_reminder_html" />
      </Text>

      <Actions
        isSubmitting={loading}
        primaryText={t(`${channel}.submit`)}
        primaryAction={async () => {
          try {
            await finalizeBulkOutreachCampaign({ variables: { id } });
            if (channel === FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_PRINT) {
              window.location.href = `${printFamilyFindingBulkOutreachCampaignPath(
                id
              )}`;
            } else {
              window.location.href = `${childRelationshipsDashboardPath({
                id: childId,
              })}?${generateTabParam("Contact Logs")}`;
            }
          } catch (err) {
            toast.negative({
              title: toastT("status.negative"),
              message: t("error_message"),
            });
          }
        }}
        secondaryText={t(`${channel}.edit`)}
        secondaryHref={`${editFamilyFindingBulkOutreachCampaignPath(
          id
        )}?${B64PARAMS}=${getEncodedBase64SearchParams()}`}
        cancelHref={childRelationshipsDashboardPath({ id: childId })}
      />
    </Fragment>
  ) : null;
};
BulkOutreachCampaignPreview.propTypes = {
  id: PropTypes.string.isRequired,
  childId: PropTypes.number.isRequired,
  channel: PropTypes.string.isRequired,
};

export default BulkOutreachCampaignPreview;
