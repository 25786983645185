import { Background } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Pill.module.scss";

/**
 * A rounded pill that displays text, e.g. a status, tag, category, or informational
 * label.
 *
 * ## Features
 * - Display simple text with color-coded backgrounds for different statuses
 * - Can include an icon from Font Awesome for additional visual context
 * - Available in regular and small sizes
 *
 * ## Rails Helpers
 * There are two main Rails helpers for creating Pills:
 *
 * ### 1. status_label
 * To display a status in Rails with the Pill component, you can use
 * `h.status_label` (`GlobalHelper#status_label`). `status_label` takes in:
 *  * `status`: the database value for the status that will be displayed
 *  * `status_map`: a map of status values to Pill variants (where the keys are
 *    all possible status values and the values are the corresponding Pill variants)
 *  * `text`: the display text for the status.
 *
 * Example:
 *
 * ```ruby
 *  h.status_label(
 *   status: record.status,
 *   status_map: {
 *     ready: :success,
 *     awaiting_response: :info,
 *     error: :warning,
 *     archived: :neutral,
 *   },
 *   text: I18n.t("some.path.#{record.status}"),
 *  )
 * ```
 *
 * ### 2. icon_pill
 * For a simpler helper that creates a small pill with an icon, use `h.icon_pill`:
 *
 * ```ruby
 * h.icon_pill(
 *   text: I18n.t("admin.child.case_status.confidential"),
 *   icon: "eye-slash",
 *   variant: :warning
 * )
 * ```
 *
 * ## React Component Usage
 *
 * Basic pill:
 * ```jsx
 * <Pill text="Active" variant="success" />
 * ```
 *
 * With an icon:
 * ```jsx
 * <Pill text="Confidential" icon="eye-slash" variant="warning" />
 * ```
 *
 * Small size:
 * ```jsx
 * <Pill text="Sealed" icon="lock" variant="warning" size="small" />
 * ```
 *
 * Available variants:
 * - `alert` - For critical or error states
 * - `neutral` - For default or neutral states (default)
 * - `info` - For informational items
 * - `success` - For successful or positive states
 * - `warning` - For caution or warning states
 */
const Pill = ({ text, icon, variant = "neutral", size }) => {
  const backgroundColor = {
    alert: "alert-100",
    neutral: "info-50",
    info: "info-100",
    success: "success-100",
    warning: "warning-100",
  };

  return (
    <Background
      as="span"
      color={backgroundColor[variant]}
      className={classNames(styles.pill, size && styles[`size-${size}`])}
      data-heart-component="Pill"
      data-variant={variant}
      data-size={size}
    >
      {icon && <i className={`fa fa-${icon}`}></i>}
      {text}
    </Background>
  );
};

Pill.propTypes = {
  /** Which variant? */
  variant: PropTypes.oneOf(["alert", "neutral", "info", "success", "warning"]),
  /** What should the pill say? */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Icon name (from Font Awesome) to display before the text */
  icon: PropTypes.string,
  /** Size variant of the pill */
  size: PropTypes.oneOf(["small"]),
};

export default Pill;
