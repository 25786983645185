import { InputDropdown, InputRadioGroup, InputText } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { If } from "tsx-control-statements/components";

import { translationWithRoot } from "@components/T";

import GCSCBucketConfigurationSubForm from "./GCSBucketConfigurationSubForm";
import SFTPConfigurationSubForm from "./SFTPConfigurationSubForm";
import getErrorsForField from "./getErrorsForField";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});

// This form is based on lib/json_schemas/integration_configurations/data_export.json
// and in the future, we could likely generate these forms from other JSON schema files
/**
 * DataExportConfigurationForm component renders a form for creating a DataExport integration
 *
 * @param {Object} props.formState - The current state of the form.
 * @param {Function} props.setFormAttribute - Function to update form attributes.
 * @param {Array} props.validationErrors - Array of validation errors.
 */
const DataExportConfigurationForm = ({
  formState,
  setFormAttribute,
  validationErrors,
}) => {
  const errorsForField = getErrorsForField(validationErrors);

  const clearDestinationFormAnswers = () => {
    setFormAttribute("externalDestinationType")("");
    setFormAttribute("gcsConfiguration.gcsBucketName")("");
    setFormAttribute("gcsConfiguration.gcsBucketPath")("");
    setFormAttribute("sftpConfiguration.hostname")("");
    setFormAttribute("sftpConfiguration.username")("");
    setFormAttribute("sftpConfiguration.port")("");
    setFormAttribute("sftpConfiguration.path")("");
  };

  return (
    <Fragment>
      <InputRadioGroup
        label={t("labels.status")}
        value={formState.status}
        onChange={setFormAttribute("status")}
        error={errorsForField("status")}
        required={true}
        values={[
          {
            label: t("labels.enabled"),
            value: "enabled",
          },
          {
            label: t("labels.disabled"),
            value: "disabled",
          },
        ]}
      />
      <InputText
        label={t("labels.point_of_contact_email")}
        type="email"
        value={formState.pointOfContactEmail}
        description={t("descriptions.must_be_agency_admin")}
        onChange={setFormAttribute("pointOfContactEmail")}
        error={errorsForField("pointOfContactEmail")}
        required={true}
      />
      <InputRadioGroup
        label={t("labels.send_notification")}
        value={formState.sendNotification}
        onChange={setFormAttribute("sendNotification")}
        error={errorsForField("sendNotification")}
        required={true}
        values={[
          {
            label: t("labels.answer_yes"),
            value: true,
          },
          {
            label: t("labels.answer_no"),
            value: false,
          },
        ]}
      />
      <InputRadioGroup
        label={t("labels.send_to_external_destination")}
        value={formState.sendToExternalDestination}
        onChange={wantToSend => {
          setFormAttribute("sendToExternalDestination")(wantToSend);
          if (!wantToSend) {
            clearDestinationFormAnswers();
          }
        }}
        error={errorsForField("sendToExternalDestination")}
        required={true}
        values={[
          {
            label: t("labels.answer_yes"),
            value: true,
          },
          {
            label: t("labels.answer_no"),
            value: false,
          },
        ]}
      />

      <If condition={formState.sendToExternalDestination}>
        <InputDropdown
          label={t("labels.external_destination_type")}
          value={formState.externalDestinationType}
          onChange={destination => {
            setFormAttribute("externalDestinationType")(destination);
            setFormAttribute(`${destination.to_lower}Configuration`)({});
          }}
          required={true}
          values={[
            {
              label: t("labels.send_to_gcs_bucket"),
              value: "GCS",
            },
            {
              label: t("labels.send_to_external_sftp"),
              value: "SFTP",
            },
          ]}
        />

        <If condition={formState.externalDestinationType === "GCS"}>
          <GCSCBucketConfigurationSubForm
            formState={formState}
            setFormAttribute={setFormAttribute}
            validationErrors={validationErrors}
          />
        </If>

        <If condition={formState.externalDestinationType === "SFTP"}>
          <SFTPConfigurationSubForm
            formState={formState}
            setFormAttribute={setFormAttribute}
            validationErrors={validationErrors}
          />
        </If>
      </If>
    </Fragment>
  );
};

DataExportConfigurationForm.propTypes = {
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
  validationErrors: PropTypes.array,
};
export default DataExportConfigurationForm;
