import { ContentTabs, Layout, Surface } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import ChildBreadcrumbs from "@components/child/ChildBreadcrumbs";
import ChildSidebar from "@components/child/ChildSidebar";
import AgencyServiceReferralsTab from "@components/prevention/tabs/AgencyServiceReferralsTab";
import DocumentsAndRequirementsTab from "@components/prevention/tabs/documents_and_requirements_tab/DocumentsAndRequirementsTab";

const { t: preventionT } = translationWithRoot("prevention.profile_page");

/**
 * This is Prevention's "Child Profile page". We're currently sharing spaces with
 * Family Finding and this is our rendition of the content we want to display.
 */
const PreventionChildProfilePage = ({
  child,
  childAgencyHumanId,
  childPermissions,
  showDocumentUploadButton,
}) => {
  const content = (
    <Surface hideTitle title={""}>
      <ContentTabs
        tabs={[
          {
            title: preventionT("tabs.agency_service_referrals"),
            contents: (
              <AgencyServiceReferralsTab recipientId={childAgencyHumanId} />
            ),
          },
          {
            title: preventionT("tabs.plan_information"),
            contents: <h2>TODO Plan Information</h2>,
          },
          {
            title: preventionT("tabs.documents"),
            contents: (
              <DocumentsAndRequirementsTab
                requirementHolderId={childAgencyHumanId}
                requirementHolderType={"AgencyHuman"}
                showDocumentUploadButton={showDocumentUploadButton}
              />
            ),
          },
        ]}
      />
    </Surface>
  );

  return (
    <Layout
      pageTitle={I18n.t("admin.child.service_referrals_dashboard_for_child", {
        child_name: child.fullName,
      })}
      breadcrumbs={
        <ChildBreadcrumbs childId={child.id} childName={child.fullName} />
      }
      main={{ content }}
      sidebar={{
        fullHeight: true,
        opaque: true,
        title: child.fullName,
        collapsibleOnDesktop: true,
        content: (
          <ChildSidebar
            currentPage="service_referrals"
            avatar={{
              firstName: child.firstName,
              lastName: child.lastName,
            }}
            label={child.fullName}
            agencyHumanId={childAgencyHumanId}
            childId={child.id}
            childFullName={child.fullName}
            childPermissions={childPermissions}
            modulePage="prevention"
          />
        ),
      }}
    />
  );
};

PreventionChildProfilePage.propTypes = {
  child: PropTypes.shape({
    id: PropTypes.number.isRequired,
    agencyId: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
  }),
  childAgencyHumanId: PropTypes.number.isRequired,
  childPermissions: PropTypes.shape({
    mayViewFamilyFindingSearches: PropTypes.bool,
    mayViewServiceReferrals: PropTypes.bool,
  }).isRequired,
  showDocumentUploadButton: PropTypes.bool.isRequired,
};

export default PreventionChildProfilePage;
