import classNames from "classnames";
import { isEmpty } from "lodash";
import { forwardRef } from "react";

import Clickable, {
  ClickableProps,
  ClickableRefType,
  cleanClickableProps,
} from "../clickable/Clickable";
import styles from "./Icon.module.scss";

type onClickAndDescription = {
  onClick: ClickableProps["onClick"];
  description: string;
};

type hrefAndDescription = {
  onClick: ClickableProps["href"];
  description: string;
};

type unclickableWithOptionalDescription = {
  onClick?: never;
  href?: never;
  description?: string;
};

type descriptionUnion =
  | onClickAndDescription
  | hrefAndDescription
  | unclickableWithOptionalDescription;

type IconInstanceProps = ClickableProps &
  descriptionUnion & {
    variant?: "activated";
  };

type IconProps = IconInstanceProps & { icon: string | string[] };

/**
 * Icons!  Heart has an `<Icon>` component that is not exported in the public
 * interface of Heart - if you get a design that uses an icon not in our set,
 * add it here!  We want to keep our icon set constrained and it's much easier
 * to statically analyze which icons are in use when we only use exports from
 * this file.
 *
 * ```js
 * import { Icons } from "@heart/components";
 * return <Icons.Plus />;
 * ```
 *
 * KNOWN ISSUES:
 *   * These are FontAwesome icons and they won't look exactly like the ones you
 * see in Figma designs.  Find the closest alternative
 * [from this site](https://fontawesome.com/v5/search) and we'll use it until
 * we get new glyphs:
 *
 * @param icon - Either a string without the `fa-` prefix for shorthand, or an array
 * of FontAwesome classnames
 * @param description - `description` indicates to screen readers what the purpose of this icon is
 * and puts useful hover text on the button. Required for clickable Icons.
 * @param href - Location, if this icon is clickable and redirects the user
 * @param onClick - onClick handler, if this icon is clickable and executes a callback.
 * @param variant - Which variant? (e.g. "activated")
 * @param disabled - Whether the clickable icon is disabled.
 * @param data-testid - Test ID for Playwright or Jest
 */
const Icon = forwardRef<ClickableRefType, IconProps>(
  ({ icon, description, href, onClick, variant, disabled, ...props }, ref) => {
    const iconArray = Array.isArray(icon) ? icon : [`fa-${icon}`];
    const iconClassName = classNames(styles.icon, ...iconArray);

    if (href || onClick) {
      if (isEmpty(description)) {
        throw new Error("description prop is required for clickable icons");
      }

      return (
        <Clickable
          aria-label={description}
          {...cleanClickableProps({
            onClick,
            href,
            disabled,
            anchorClassname: classNames(styles.clickableIconAnchor, {
              [styles[variant]]: variant,
            }),
            buttonClassname: classNames(styles.clickableIconButton, {
              [styles[variant]]: variant,
            }),
          })}
          title={description}
          ref={ref}
          data-heart-component="ClickableIcon"
          {...props}
        >
          {<i className={iconClassName} />}
        </Clickable>
      );
    }

    return <i className={iconClassName} title={description} />;
  }
);
Icon.displayName = "Icon";

export default Icon;

/* eslint-disable react/display-name */
export const ArrowLeft = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="arrow-left" {...props} ref={ref} />
);
export const ArrowRight = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="arrow-right" {...props} ref={ref} />
);
export const Ban = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="ban" {...props} ref={ref} />
);
export const Bars = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="bars" {...props} ref={ref} />
);
export const Calendar = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => (
    <Icon icon={["fa-calendar-alt", "far"]} {...props} ref={ref} />
  )
);
export const CaretDown = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="caret-down" {...props} ref={ref} />
);
export const CaretUp = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="caret-up" {...props} ref={ref} />
);
export const Check = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="check" {...props} ref={ref} />
);
export const ChevronDown = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="chevron-down" {...props} ref={ref} />
);
export const ChevronLeft = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="chevron-left" {...props} ref={ref} />
);
export const ChevronRight = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="chevron-right" {...props} ref={ref} />
);
export const ChevronUp = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="chevron-up" {...props} ref={ref} />
);
export const CommentAlt = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="comment-alt" {...props} ref={ref} />
);
export const AngleLeft = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="angle-left" {...props} ref={ref} />
);
export const AngleRight = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="angle-right" {...props} ref={ref} />
);
export const AngleDoubleLeft = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="angle-double-left" {...props} ref={ref} />
);
export const AngleDoubleRight = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="angle-double-right" {...props} ref={ref} />
);
export const CheckCircle = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="check-circle" {...props} ref={ref} />
);
export const CopyToClipboard = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="copy" {...props} ref={ref} />
);
export const Edit = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="edit" {...props} ref={ref} />
);
export const Ellipsis = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="ellipsis-h" {...props} ref={ref} />
);
export const EllipsisVertical = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="ellipsis-v" {...props} ref={ref} />
);
export const Envelope = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="envelope" {...props} ref={ref} />
);
export const ExclamationTriangle = forwardRef<
  ClickableRefType,
  IconInstanceProps
>((props, ref) => <Icon icon="exclamation-triangle" {...props} ref={ref} />);
export const Eye = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="eye" {...props} ref={ref} />
);
export const EyeSlash = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="eye-slash" {...props} ref={ref} />
);
export const File = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="file" {...props} ref={ref} />
);
export const FileDownload = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="file-download" {...props} ref={ref} />
);
export const FileUpload = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="file-upload" {...props} ref={ref} />
);
export const Filter = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="filter" {...props} ref={ref} />
);
export const Handshake = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="handshake" {...props} ref={ref} />
);
export const Hourglass = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="hourglass-half" {...props} ref={ref} />
);
export const Image = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="image" {...props} ref={ref} />
);
export const Images = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="images" {...props} ref={ref} />
);
export const InfoCircle = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="info-circle" {...props} ref={ref} />
);
export const Language = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => (
    <Icon icon={["fa-language", "fa-lg", "fas"]} {...props} ref={ref} />
  )
);
export const List = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="list" {...props} ref={ref} />
);
export const Lock = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="lock" {...props} ref={ref} />
);
export const Microphone = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="microphone" {...props} ref={ref} />
);
export const MicrophoneSlash = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="microphone-slash" {...props} ref={ref} />
);
export const Minus = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="minus" {...props} ref={ref} />
);
export const PaperPlane = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="paper-plane" {...props} ref={ref} />
);
export const Parachute = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="parachute-box" {...props} ref={ref} />
);
export const Pause = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="pause" {...props} ref={ref} />
);
export const PenSquare = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="pen-square" {...props} ref={ref} />
);
export const Pencil = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="pencil-alt" {...props} ref={ref} />
);
export const Phone = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="phone" {...props} ref={ref} />
);
export const Play = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="play" {...props} ref={ref} />
);
export const Plus = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="plus" {...props} ref={ref} />
);
export const Redo = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="redo" {...props} ref={ref} />
);
export const Search = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="search" {...props} ref={ref} />
);
export const Sitemap = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="sitemap" {...props} ref={ref} />
);
export const Sort = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="sort" {...props} ref={ref} />
);
export const Spinner = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => (
    <Icon icon={["fa-circle-notch", "fa-spin", "fa-fw"]} {...props} ref={ref} />
  )
);
export const Star = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="star" {...props} ref={ref} />
);
export const StickyNote = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="sticky-note" {...props} ref={ref} />
);
export const Stop = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="stop" {...props} ref={ref} />
);
export const Tasks = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="tasks" {...props} ref={ref} />
);
export const Times = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="times" {...props} ref={ref} />
);
export const TimesCircle = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="times-circle" {...props} ref={ref} />
);
export const Trash = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="trash" {...props} ref={ref} />
);
export const Users = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="users" {...props} ref={ref} />
);
export const VolumeUp = forwardRef<ClickableRefType, IconInstanceProps>(
  (props, ref) => <Icon icon="volume-up" {...props} ref={ref} />
);
/* eslint-enable react/display-name */
