import { Link, generateTabParam } from "@heart/components";
import { compact } from "lodash";
import { childRelationshipsDashboardPath } from "routes";

import { translationWithRoot } from "@components/T";

import Actions from "./Actions";

const { t } = translationWithRoot("family_finding.searches");

const toDate = dateString => new Date(`${dateString} 00:00:00`);

const displayDate = date => toDate(date).toLocaleDateString("en-US");

// both dates are just month-day-year, in a format accepted by
// Date.parse -- eg 11/20/2020, 2020-11-20, etc
// startDate is required, endDate is not and will default to today
const dateDiff = (startDate, endDate) => {
  const end = endDate ? toDate(endDate) : new Date();
  end.setHours(0, 0, 0, 0);
  const start = toDate(startDate);

  return Math.floor((end - start) / (24 * 60 * 60 * 1000));
};

const nameCol = {
  columnName: { name: t("columns.child") },
  id: "child",
  cell: search => (
    <Link href={childRelationshipsDashboardPath(search.agencyHuman.childId)}>
      {search.agencyHuman.fullName}
    </Link>
  ),
  sortBy: "childName",
};

const agencyCol = {
  columnName: { name: t("columns.agency") },
  id: "agencyName",
  cell: "agency.name",
};

const startDateCol = {
  columnName: { name: t("columns.start_date") },
  id: "startDate",
  cell: ({ startDate }) => displayDate(startDate),
  sortBy: "startDate",
};

const endDateCol = {
  columnName: { name: t("columns.end_date") },
  id: "endDate",
  cell: ({ endDate }) => (endDate ? displayDate(endDate) : null),
  sortBy: "endDate",
};

const lengthCol = {
  columnName: { name: t("columns.length_of_search") },
  id: "lengthOfSearch",
  cell: ({ endDate, startDate }) => {
    const days = dateDiff(startDate, endDate);
    return `${days} ${t("columns.days", { count: days })}`;
  },
  sortBy: "length",
};

const totalConnectionsFoundCol = {
  columnName: { name: t("columns.total_connections_found") },
  id: "totalConnectionsFound",
  cell: search => (
    <Link href={childRelationshipsDashboardPath(search.agencyHuman.childId)}>
      {search.totalConnectionsFound}
    </Link>
  ),
  sortBy: "totalConnectionsFound",
};

const statusCol = {
  columnName: { name: t("columns.status") },
  id: "status",
  cell: ({ endDate }) => (endDate ? t("tabs.closed") : t("tabs.open")),
};

const totalContactsCol = {
  columnName: { name: t("columns.total_contacts") },
  id: "totalContacts",
  cell: search => (
    <Link
      href={`${childRelationshipsDashboardPath(
        search.agencyHuman.childId
      )}?${generateTabParam("Contact Logs")}`}
    >
      {search.totalContacts}
    </Link>
  ),
  sortBy: "totalContacts",
};

const uniqueContactsCol = {
  columnName: { name: t("columns.unique_contacts") },
  id: "uniqueContacts",
  cell: "uniqueContacts",
  sortBy: "uniqueContacts",
};

const successfulContactsCol = {
  columnName: { name: t("columns.unique_successful_contacts") },
  id: "successfulContacts",
  cell: "successfulContacts",
  sortBy: "uniqueSuccessfulContacts",
};

const regionsCol = {
  columnName: { name: t("columns.regions_assigned") },
  id: "regionsAssigned",
  cell: ({ agencyHuman: { regions } } = { agencyHuman: { regions: [] } }) =>
    regions.join(", "),
};

const countiesCol = {
  columnName: { name: t("columns.counties_assigned") },
  id: "countiesAssigned",
  cell: ({ agencyHuman: { counties } } = { agencyHuman: { counties: [] } }) =>
    counties.join(", "),
};

const workersCol = {
  columnName: { name: t("columns.worker_assignment") },
  id: "workerAssignment",
  cell: ({ assignedWorkers }) =>
    assignedWorkers.map(({ name }) => name).join(", "),
};

const actionsCol = () => ({
  columnName: { name: t("columns.actions"), justify: "end" },
  id: "actions",
  cell: search => <Actions search={search} />,
});

/** The column generator for the Family Finding searches dashboard table */
const getColumns = ({ tab, currentUser = {}, agency = {} }) => {
  const actions = actionsCol();

  if (tab === "open") {
    return compact([
      nameCol,
      currentUser.bintiAdmin && agencyCol,
      startDateCol,
      lengthCol,
      totalConnectionsFoundCol,
      totalContactsCol,
      uniqueContactsCol,
      successfulContactsCol,
      workersCol,
      (currentUser.bintiAdmin || agency.childRegionsEnabled) && regionsCol,
      (currentUser.bintiAdmin || agency.childCountiesEnabled) && countiesCol,
      actions,
    ]);
  }
  if (tab === "closed") {
    return compact([
      nameCol,
      currentUser.bintiAdmin && agencyCol,
      startDateCol,
      endDateCol,
      lengthCol,
      totalConnectionsFoundCol,
      totalContactsCol,
      uniqueContactsCol,
      successfulContactsCol,
      workersCol,
      (currentUser.bintiAdmin || agency.childRegionsEnabled) && regionsCol,
      (currentUser.bintiAdmin || agency.childCountiesEnabled) && countiesCol,
      actions,
    ]);
  }

  return compact([
    nameCol,
    currentUser.bintiAdmin && agencyCol,
    startDateCol,
    endDateCol,
    lengthCol,
    statusCol,
    workersCol,
    (currentUser.bintiAdmin || agency.childRegionsEnabled) && regionsCol,
    (currentUser.bintiAdmin || agency.childCountiesEnabled) && countiesCol,
    actions,
  ]);
};

export default getColumns;
