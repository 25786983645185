import {
  Breadcrumbs,
  Button,
  ContentTabs,
  Flex,
  GraphQLDataTable,
  Layout,
  Surface,
} from "@heart/components";
import PropTypes from "prop-types";
import { adminChildrenPath, newAgencyServicePath } from "routes";
import { If } from "tsx-control-statements/components";

import { translationWithRoot } from "@components/T";
import CreateFilters from "@components/prevention/agency_services/table/CreateFilters";
import columns from "@components/prevention/agency_services/table/columns";

import AgencyServiceRates from "@graphql/queries/prevention/AgencyServiceRates.graphql";

const { t: agencyServiceT } = translationWithRoot("prevention.agency_services");

/**
 * Component to display a list of Agency Services
 * @param fundingSources - list of funding sources allowing users to
 * filter agency services.
 * @returns {JSX.Element}
 * @constructor
 */
const AgencyServices = ({
  showNewServiceButton,
  showCreateServiceReferralButton,
  fundingSources,
}) => {
  const breadcrumbs = (
    <Breadcrumbs
      pages={[
        {
          href: adminChildrenPath(),
          label: agencyServiceT("dashboard.breadcrumbs.children_and_youth"),
        },
        { href: "#", label: agencyServiceT("dashboard.title") },
      ]}
    />
  );

  const content = (
    <ContentTabs
      tabs={[
        {
          title: agencyServiceT("dashboard.tabs.all"),
          contents: (
            <Surface title={agencyServiceT("dashboard.title")} hideTitle>
              <GraphQLDataTable
                query={AgencyServiceRates}
                queryTitle="preventionAgencyServiceRate"
                columns={columns({ showCreateServiceReferralButton })}
                filters={CreateFilters(fundingSources)}
                defaultSort={{ sortBy: "end_date", sortDirection: "ASC" }}
                actions={
                  <If condition={showNewServiceButton}>
                    <Flex justify="end" column>
                      <Button href={newAgencyServicePath()} variant="secondary">
                        {agencyServiceT("create_new")}
                      </Button>
                    </Flex>
                  </If>
                }
              />
            </Surface>
          ),
        },
      ]}
    />
  );

  return (
    <Layout
      pageTitle={agencyServiceT("dashboard.title")}
      breadcrumbs={breadcrumbs}
      main={{ content }}
    />
  );
};

AgencyServices.propTypes = {
  showCreateServiceReferralButton: PropTypes.bool,
  fundingSources: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  showNewServiceButton: PropTypes.bool,
};

export default AgencyServices;
