import { useMutation } from "@apollo/client";
import {
  Button,
  Flex,
  FlexItem,
  InputDate,
  InputPhone,
  InputFilterable,
  InputText,
  Modal,
} from "@heart/components";
import classnames from "classnames";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";

import InputPanel from "@components/reusable_ui/forms/InputPanel";
import BannerContainer from "@components/shared/banner/BannerContainer";

import CreateHumanPlacementProviderRole from "@graphql/mutations/CreateHumanPlacementProviderRole.graphql";
import UpdateHumanPlacementProviderRole from "@graphql/mutations/UpdateHumanPlacementProviderRole.graphql";

import preventDefault from "@lib/preventDefault";

import constructName from "../constructName";
import determineUserAgencyProfile from "../determineUserAgencyProfile";
import isPlaceholderEmail from "../isPlaceholderEmail";
import addOrEditAdult from "./addOrEditAdult";
import { getPlacementProviderChangeRefetchQueries } from "./placementProviderChangeUtils";

const pattern = input => (isEmpty(input) ? ".*" : ".{7,15}");
/* This component is used as both an add and edit form, as determined
 *   by its parent element OtherAdultsForm. If an existingAdult is provided,
 *   the values from that adult will be used to populate the form values,
 *   otherwise all fields will start blank except the subrole.
 *  Edit Application > "List Another Household Member"
 */
const OtherAdultEditor = ({
  // Model data
  agencyId,
  applicationId,
  placementProviderId,
  existingAdult,
  otherAdultTypes = [],
  existingUserAgencyProfiles = {},
  // Component configuration
  hasDateOfBirthInput = false,
  disableEditJoinedAt,
  // React state
  messageDisplay,
  setAddingNewAdult,
  setEditingForAdult,
  localErrorMessages,
  setlocalErrorMessages,
}) => {
  const refetchQueries = getPlacementProviderChangeRefetchQueries({
    applicationId,
    placementProviderId,
  });

  const [createHumanPlacementProviderRole] = useMutation(
    CreateHumanPlacementProviderRole,
    { refetchQueries }
  );
  const [updateHumanPlacementProviderRole] = useMutation(
    UpdateHumanPlacementProviderRole,
    { refetchQueries }
  );

  const userAgencyProfile = determineUserAgencyProfile({
    agencyId,
    person: existingAdult || {},
  });

  const constructAdultObject = () => {
    const {
      id = null,
      firstName,
      middleName,
      lastName,
      suffix,
      subrole = otherAdultTypes[0],
      joinedAt,
      phoneNumbers = [],
    } = existingAdult || {};
    const {
      email = "",
      id: userAgencyProfileId,
      dateOfBirth,
    } = userAgencyProfile;

    const primaryPhoneNumber = phoneNumbers.filter(({ primary }) => primary);
    const { phoneNumber = "" } = isEmpty(primaryPhoneNumber)
      ? {}
      : primaryPhoneNumber[0];

    return {
      id,
      firstName: firstName || "",
      middleName: middleName || "",
      lastName: lastName || "",
      suffix: suffix || "",
      subrole,
      email,
      phoneNumber,
      userAgencyProfileId,
      supressInviteEmail: false,
      dateOfBirth,
      joinedAt,
    };
  };

  const [adult, setAdult] = useState(constructAdultObject(existingAdult));
  const [submittingEmailChange, setSubmittingEmailChange] = useState(false);

  const existingEmailUpdated = () =>
    existingAdult && adult.email !== userAgencyProfile.email;
  const emailUpdated = () =>
    !existingAdult || adult.email !== userAgencyProfile.email;

  const submitText = () => {
    let text = I18n.t("views.reference_requests.form.save");
    if (!existingAdult) {
      text = I18n.t("views.reference_requests.form.save_and_send_request");
    }
    return text;
  };

  const validate = () => {
    const errs = [];
    const { email = "" } = adult;
    if (
      emailUpdated() &&
      existingUserAgencyProfiles[email] &&
      !existingUserAgencyProfiles[email].leftAt
    ) {
      errs.push(
        I18n.t(
          "views.applications.application_other_adult.email_already_in_use"
        )
      );
    }

    if (errs.length > 0) {
      setlocalErrorMessages(errs);
      return false;
    }

    return true;
  };

  const onSubmit = preventDefault(() => {
    if (existingEmailUpdated()) {
      setSubmittingEmailChange(true);
    } else {
      finishSubmit();
    }
  });

  const finishSubmit = () => {
    const valid = validate();
    if (valid) {
      addOrEditAdult({
        applicationId,
        createHumanPlacementProviderRole,
        updateHumanPlacementProviderRole,
        messageDisplay,
        placementProviderId,
        setAddingNewAdult,
        setEditingForAdult,
        adult,
        setlocalErrorMessages,
        existingUserAgencyProfiles,
      });
    }
    setSubmittingEmailChange(false);
  };

  return (
    <Fragment>
      <form
        onSubmit={onSubmit}
        className={classnames({ "space-below-2": existingAdult })}
        data-testid="other-adult-editor"
      >
        <InputPanel
          title={`${I18n.t(
            `views.reference_requests.form.${existingAdult ? "edit" : "add"}`
          )} ${I18n.t("views.reference_requests.form.adult_information")}`}
        >
          {I18n.t("views.reference_requests.form.name_warning")}
          <Flex row as="span">
            <FlexItem expand="sm">
              <InputText
                type="text"
                label={I18n.t("common.first_name")}
                required
                value={adult.firstName}
                onChange={value => setAdult({ ...adult, firstName: value })}
              />
            </FlexItem>
            <FlexItem expand="sm">
              <InputText
                type="text"
                label={I18n.t("common.middle_name")}
                value={adult.middleName}
                onChange={value => setAdult({ ...adult, middleName: value })}
              />
            </FlexItem>
          </Flex>
          <Flex row as="span">
            <FlexItem expand="sm">
              <InputText
                type="text"
                label={I18n.t("common.last_name")}
                required={true}
                value={adult.lastName}
                onChange={value => setAdult({ ...adult, lastName: value })}
              />
            </FlexItem>
            <FlexItem expand="sm">
              <InputText
                type="text"
                label={I18n.t("common.suffix")}
                value={adult.suffix}
                onChange={value => setAdult({ ...adult, suffix: value })}
              />
            </FlexItem>
          </Flex>
          <If condition={!isEmpty(otherAdultTypes)}>
            <InputFilterable
              label={I18n.t("views.reference_requests.form.role_in_household")}
              onChange={({ value }) => setAdult({ ...adult, subrole: value })}
              value={{
                value: adult.subrole,
                label: adult.subrole
                  ? I18n.t(
                      `activerecord.enums.application_other_adult.adult_types.${adult.subrole}`
                    )
                  : "",
              }}
              options={otherAdultTypes.map(role => ({
                value: role,
                label: I18n.t(
                  `activerecord.enums.application_other_adult.adult_types.${role}`
                ),
              }))}
            />
          </If>
          <InputText
            type="text"
            label={I18n.t("views.common.email_address")}
            value={
              isPlaceholderEmail({ email: adult.email }) ? "" : adult.email
            }
            onChange={value =>
              setAdult({
                ...adult,
                email: value,
                supressInviteEmail: !emailUpdated(),
              })
            }
            placeholder="hello@example.com"
            pattern="^['\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]{2,}$"
          />
          <InputPhone
            label={I18n.t("views.common.phone_number")}
            pattern={pattern(adult.phoneNumber || "")}
            value={adult.phoneNumber}
            defaultCountry="US"
            international
            onChange={value => setAdult({ ...adult, phoneNumber: value })}
          />
          <If condition={hasDateOfBirthInput}>
            <InputDate
              label={I18n.t("views.common.date_of_birth")}
              name="adult[date_of_birth]"
              value={adult.dateOfBirth}
              onChange={date => setAdult({ ...adult, dateOfBirth: date })}
            />
          </If>
          <InputDate
            label={I18n.t("views.reference_requests.form.joined_the_home")}
            name="adult[joined_at]"
            value={adult.joinedAt || DateTime.local().toISODate()}
            onChange={date => setAdult({ ...adult, joinedAt: date })}
            required
            disabled={disableEditJoinedAt}
            description={
              disableEditJoinedAt
                ? I18n.t(
                    "views.applications.application_other_adult.joined_at_disabled_reason"
                  )
                : null
            }
          />
          <If condition={!isEmpty(localErrorMessages)}>
            <BannerContainer
              type={"error"}
              /* localErrorMessages is an array that contains strings or JSX components.
               * 'reduce' syntax allow us to join these two different types of values.
               */
              message={localErrorMessages.reduce((prev, curr) => [
                prev,
                ", ",
                curr,
              ])}
            />
          </If>
          <Flex row className="space-above-3">
            <Button type="submit" variant="primary">
              {submitText()}
            </Button>
            <Button
              variant="tertiary"
              onClick={preventDefault(() => {
                if (existingAdult) {
                  setEditingForAdult(false);
                } else {
                  setAddingNewAdult(false);
                }
              })}
            >
              {I18n.t("views.common.cancel")}
            </Button>
          </Flex>
        </InputPanel>
      </form>
      <Modal
        hidden={!submittingEmailChange}
        title={I18n.t(
          "views.applications.application_other_adult.email_change_confirmation"
        )}
        onCancel={() => setSubmittingEmailChange(false)}
        onSubmit={finishSubmit}
        displayLargeTitle
      >
        {I18n.t(
          "views.applications.application_other_adult.email_change_warning",
          {
            name: constructName(adult),
            list_another_household_member: I18n.t(
              "views.applications.application_other_adult.list_another_household_member"
            ),
          }
        )}
      </Modal>
    </Fragment>
  );
};

OtherAdultEditor.propTypes = {
  agencyId: PropTypes.number.isRequired,
  applicationId: PropTypes.number.isRequired,
  placementProviderId: PropTypes.number.isRequired,
  messageDisplay: PropTypes.object.isRequired,
  existingAdult: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    subrole: PropTypes.string,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        phoneNumber: PropTypes.string,
        primary: PropTypes.bool,
      })
    ),
    userAgencyProfiles: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
      })
    ),
  }),
  otherAdultTypes: PropTypes.array.isRequired,
  setAddingNewAdult: PropTypes.func.isRequired,
  setEditingForAdult: PropTypes.func.isRequired,
  /* This is a map from email strings to the UserAgencyProfile
  of that email. */
  existingUserAgencyProfiles: PropTypes.object,
  /* Boolean flag as to whether to we want to show/capture date of birth,
   this varies depending on context of who is viewing the component.
   Defaults to true (see above) */
  hasDateOfBirthInput: PropTypes.bool,
  /* localErrorMessages is an array that contains error strings or JSX components.
   * Its state is set originially in the parent, OtherAdultForm, and receives updates
   * both in this component and in addOrEditAdult, a function also passed in from the parent.
   */
  localErrorMessages: PropTypes.array.isRequired,
  setlocalErrorMessages: PropTypes.func.isRequired,
  disableEditJoinedAt: PropTypes.bool,
};

export default OtherAdultEditor;
