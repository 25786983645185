import { Link, Surface, Text, Upload } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";

import { translationWithRoot } from "@components/T";

import TranscriptionInput from "./TranscriptionInput";
import { MICROPHONE, RECORDING } from "./constants";

const { t, T } = translationWithRoot("transcription.provide_recording_page");

/**
 * Second page of the wizard for creating a new transcription process
 * where the user provides the recording to be transcribed either by
 * recording it with their microphone or uploading a file.
 */
const ProvideRecordingPage = ({
  transcriptionSource,
  setRecordingFile,
  recordingFile,
}) => {
  const recordingFileUrl = useMemo(
    () => recordingFile && URL.createObjectURL(recordingFile),
    [recordingFile]
  );

  // required for the audio element to know what type of file it's playing in Safari
  const typeProps =
    recordingFile && transcriptionSource === MICROPHONE
      ? { type: "audio/mp4" }
      : {};

  return (
    <Fragment>
      <Choose>
        <When condition={transcriptionSource === MICROPHONE}>
          <Surface
            title={t("record_audio")}
            subtitle={t("recording_instructions")}
          >
            <TranscriptionInput
              onNewTranscription={setRecordingFile}
              recordingFile={recordingFile}
            />
          </Surface>
        </When>
        <When condition={transcriptionSource === RECORDING}>
          <Surface
            title={t("upload_audio")}
            subtitle={t("upload_instructions")}
          >
            <Upload
              onUpload={files => setRecordingFile(files[0])}
              multiple={false}
              audioOnly
            />
          </Surface>
        </When>
      </Choose>
      <If condition={recordingFile}>
        <Surface title={t("recordings")}>
          <If condition={transcriptionSource === RECORDING}>
            {/* Link is not TypeScript compatible at the moment */}
            <Link href={recordingFileUrl} target="_blank">
              {recordingFile.name}
            </Link>
          </If>
          {/*
            The user provided this audio, so presumably they know what
            it contains. regardless we'll transcribe it for them in
            due time...

            Note that we have to use a key here to force the audio
            element to reload the file when the source src changes.
            See this comment for details:
            https://github.com/facebook/react/issues/9447#issuecomment-294586275
          */}
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio controls key={recordingFileUrl}>
            <source src={recordingFileUrl} {...typeProps} />
          </audio>
        </Surface>
      </If>
    </Fragment>
  );
};

ProvideRecordingPage.propTypes = {
  transcriptionSource: PropTypes.oneOf([MICROPHONE, RECORDING]),
  confirm: PropTypes.func.isRequired,
  setRecordingFile: PropTypes.func.isRequired,
  recordingFile: PropTypes.object,
};

export default ProvideRecordingPage;
