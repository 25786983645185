import { useQuery } from "@apollo/client";
import {
  Button,
  Icons,
  Flex,
  LoadingOverlay,
  NodeDiagram,
  Toggle,
} from "@heart/components";
import { pull, uniqBy } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";

import AgencyHumanGenogramData from "@graphql/queries/AgencyHumanGenogramData.graphql";

import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

import useAgencyHumanPermissions from "../../agency_humans/useAgencyHumanPermissions";
import ViewRelationshipModal from "../relationships/ViewRelationshipModal";
import { determineNonKeystoneId } from "../relationships/relationshipConversion";
import ChildNode from "./ChildNode";
import ConnectionNode from "./ConnectionNode";

const { t } = translationWithRoot("relationships");

const customNodes = { personNode: ConnectionNode, childNode: ChildNode };
const position = { x: 0, y: 0 };

/** A first iteration of a genogram, using only generational layers and
 * one degree relationships off of the child
 *
 * This is a super super super rough draft - eventually we'll need to change
 * a lot of the logic to implement the one degree relationships that will help
 * shape this into a better genogram shape. I'm not worrying too much about
 * the cleanliness of the code here because I'm going to be making so many changes
 * to it!
 */
const Genogram = ({ addPersonFormPath, childAgencyHumanId }) => {
  const permissions = useAgencyHumanPermissions(childAgencyHumanId);
  const subview = useBase64SearchParam("subview");
  const isFictiveKinView = subview === "fictiveKin";

  const [reactFlowInstance, setReactFlowInstance] = useState();
  const [relationshipToView, setRelationshipToView] = useState();
  const {
    data: genogramData = {
      agencyHumanGenogramData: {
        layers: {},
        fictiveKin: [],
        parentChildRelationships: {},
        siblingRelationships: [],
        childrenWithKnownParentsIds: [],
      },
    },
    loading,
  } = useQuery(AgencyHumanGenogramData, {
    variables: { agencyHumanId: childAgencyHumanId },
    /** Once the query is complete, we want to center the view of the genogram */
    onCompleted: () => setTimeout(reactFlowInstance?.fitView, 50),
  });
  const {
    childrenWithKnownParentsIds,
    fictiveKin,
    keystoneAgencyHuman,
    layers,
    siblingRelationships,
  } = genogramData.agencyHumanGenogramData;

  let childNode;
  const kinNodes = [
    /** Adding a hidden node that connects to all the nodes of the graph.
     *
     * This is necessary as nodes without any edges attached do not take their
     * partition into account, which means we don't get generational lines
     * among folks that aren't connected via 1 degree relationships
     */
    {
      id: "hiddenConnectorNode",
      hidden: true,
      position,
    },
  ];
  const kinDynamicEdges = [];
  const kinStaticEdges = [];

  const fictiveKinNodes = [];
  const fictiveKinDynamicEdges = [];

  const generateNode = ({
    id,
    relationship,
    partition,
    hidden,
    isFictiveKin = false,
  }) => {
    /** We assume the node being added is for the non-keystone agency human
     * unless another id is provided. Other ids may be provided for hidden nodes,
     * utilized to get nodes to lay out correctly in the genogram, for central
     * nodes between a set of siblings and their parent to ensure the lineage is
     * clearly indicated, etc.
     */
    const nodeId =
      id ||
      determineNonKeystoneId({
        keystoneAgencyHumanId: childAgencyHumanId,
        relationship,
      });
    const node = {
      id: nodeId,
      type: "personNode",
      data: {
        keystoneAgencyHumanId: childAgencyHumanId,
        relationship,
        setRelationshipToView,
      },
      position,
      hidden,
      layoutOptions: {
        "partitioning.partition": partition,
      },
    };

    if (isFictiveKin) fictiveKinNodes.push(node);
    else kinNodes.push(node);

    return node;
  };

  /** For individuals intended to be displayed as part of the same generation
   * who do not have a known parent-child relationship, we add a phantom node
   * where the parent node would be. This ensures that the nodes intended to
   * sit next to each other do so
   *
   * Without this logic, the nodes have a tendency to stack vertically instead
   * of sitting in line with each other horizontally
   */
  const addPhantomSameGenerationRelationship = ({ humanId1, humanId2 }) => {
    const phantomNodeId = `${humanId1}-${humanId2}-phantomNode`;
    const edgeId = `e${humanId1}-${humanId2}`;

    generateNode({ id: phantomNodeId, hidden: true });
    kinDynamicEdges.push({
      id: `e${edgeId}-phantomNode1`,
      source: phantomNodeId,
      target: humanId1,
      sourceHandle: "parent",
      targetHandle: "child",
      type: "step",
    });
    kinDynamicEdges.push({
      id: `e${edgeId}-phantomNode2`,
      source: phantomNodeId,
      target: humanId2,
      sourceHandle: "parent",
      targetHandle: "child",
      type: "step",
    });
    kinStaticEdges.push({
      id: `e${humanId1}-${humanId2}`,
      source: humanId1,
      target: humanId2,
      sourceHandle: "sibling",
      targetHandle: "sibling",
      type: "step",
    });
  };

  const childAgencyHuman = keystoneAgencyHuman;
  if (childAgencyHuman) {
    childNode = {
      id: childAgencyHuman.id,
      type: "childNode",
      data: childAgencyHuman,
      position,
      /** The child sits at layer 6, see models/relationship.rb for a
       * diagram of how the genogram layers fall
       */
      layoutOptions: {
        "partitioning.partition": isFictiveKinView ? undefined : 6,
      },
    };
    kinNodes.push(childNode);
    fictiveKinNodes.push(childNode);
  }

  fictiveKin.forEach(relationship => {
    const node = generateNode({ relationship, isFictiveKin: true });
    fictiveKinDynamicEdges.push({
      id: `${childNode.id}-${node.id}`,
      source: childNode.id,
      target: node.id,
      sourceHandle: "fictive-kin",
      targetHandle: "fictive-kin",
      type: "floating",
    });
  });

  pull(Object.keys(layers), "__typename").forEach(key => {
    layers[key].forEach(relationship => {
      const node = generateNode({
        relationship,
        partition: parseInt(key.replace("_", ""), 10),
      });
      /** Adding an edge between all of the nodes that are included in the genogram and a hidden
       * node that connects to them all. This is necessary for the partitions to have an impact, as
       * nodes without any edges attached do not take their partition into account. These edges
       * will not be visible in the final genogram.
       */
      kinDynamicEdges.push({
        id: `${node.id}-connector`,
        source: "hiddenConnectorNode",
        target: node.id,
        type: "straight",
      });

      if (
        ["daughter", "son", "child"].includes(relationship.kinshipRelationship)
      ) {
        kinDynamicEdges.push({
          id: `${childNode.id}-${node.id}`,
          source: node.id,
          target: childNode.id,
          sourceHandle: "parent",
          targetHandle: "child",
          type: "step",
        });
      } else if (
        [
          "married",
          "divorced",
          "engaged",
          "dating",
          "cohabitating",
          "exes",
          "partner",
        ].includes(relationship.kinshipRelationship)
      )
        kinDynamicEdges.push({
          id: `${childNode.id}-${node.id}`,
          source: childNode.id,
          target: node.id,
          sourceHandle: "partner",
          targetHandle: "partner",
          type: "step",
        });
      else if (
        ["mother", "father", "parent"].includes(
          relationship.kinshipRelationship
        )
      )
        kinDynamicEdges.push({
          id: `${childNode.id}-${node.id}`,
          source: childNode.id,
          target: node.id,
          sourceHandle: "parent",
          targetHandle: "child",
          type: "step",
        });
    });
  });

  siblingRelationships.forEach(relationship => {
    generateNode({ id: relationship.sourceAgencyHuman.id, relationship });
    generateNode({ id: relationship.destinationAgencyHuman.id, relationship });
    const human1IsChild = childrenWithKnownParentsIds.includes(
      relationship.sourceAgencyHuman.id
    );
    const human2IsChild = childrenWithKnownParentsIds.includes(
      relationship.destinationAgencyHuman.id
    );

    /** If both children have at least one parent, we don't need to do anything special.
     * However, if they're not we need to add a "phantom" parent to put them horizontally
     * next to each other. Without this logic the nodes will generally stack vertically
     */
    if (!(human1IsChild && human2IsChild)) {
      addPhantomSameGenerationRelationship({
        humanId1: relationship.sourceAgencyHuman.id,
        humanId2: relationship.destinationAgencyHuman.id,
      });
    }
  });

  return (
    <LoadingOverlay active={loading}>
      <ViewRelationshipModal
        relationship={relationshipToView}
        keystoneAgencyHumanId={childAgencyHumanId}
        setRelationshipToView={setRelationshipToView}
      />
      <Flex justify="end">
        <If condition={permissions.mayCreateRelationship()}>
          <Button variant={"primary"} href={addPersonFormPath}>
            {t("table.add_connection")}
          </Button>
        </If>
        <Toggle
          LeftIcon={Icons.List}
          RightIcon={Icons.Sitemap}
          leftDescription={t("view_table")}
          rightDescription={t("view_genogram")}
          leftView="table"
          rightView="genogram"
        />
      </Flex>
      <Flex justify="center">
        <Toggle
          queryParam="subview"
          leftDescription={t("genogram.relatives")}
          rightDescription={t("genogram.fictive_kin")}
          leftView="relatives"
          rightView="fictiveKin"
          iconOnly={false}
        />
      </Flex>
      <NodeDiagram
        nodes={uniqBy(isFictiveKinView ? fictiveKinNodes : kinNodes, "id")}
        dynamicEdges={uniqBy(
          isFictiveKinView ? fictiveKinDynamicEdges : kinDynamicEdges,
          "id"
        )}
        staticEdges={uniqBy(isFictiveKinView ? [] : kinStaticEdges, "id")}
        graphDirection="UP"
        nodeHeight={200}
        nodeWidth={200}
        customNodes={customNodes}
        onInit={instance => setReactFlowInstance(instance)}
        downloadFileName={`${childAgencyHuman?.fullName}_genogram_${
          isFictiveKinView ? "fictive_kin" : "relatives"
        }_${DateTime.local().toISODate()}`}
        spokeAndWheel={isFictiveKinView}
      />
    </LoadingOverlay>
  );
};
Genogram.propTypes = {
  addPersonFormPath: PropTypes.string,
  childAgencyHumanId: PropTypes.number.isRequired,
};

export default Genogram;
